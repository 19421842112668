.table-primary-blue
  color: $dark-65
  border-color: rgb(42, 128, 199, 0.25)
  background-color: $lighter-blue

  thead
    font-weight: 500

  tbody
    tr:hover
      background-color: $primary-blue
      color: $white
      a
        color: $lighter-blue
        &:hover
          color: $light-blue

.table-primary-blue > :not(:last-child) > :last-child > *
  border-bottom-color: $primary-blue

@include media-breakpoint-up(lg)
  .table-primary-blue
    th, td
      padding: 1rem
