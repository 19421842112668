[data-sal|="fade"].sal-animate
  --sal-duration: 1s

[data-sal|="slide-up"].sal-animate
  --sal-duration: 0.6s

[data-sal|="slide-right"].sal-animate
  --sal-duration: 0.6s

[data-sal|="zoom-in"].sal-animate
  --sal-duration: 0.8s

[data-sal|="flip-down"].sal-animate
  --sal-duration: 0.8s

[data-sal|="flip-right"].sal-animate
  --sal-duration: 0.8s

[data-sal|="flip-left"].sal-animate
  --sal-duration: 0.8

// disable sal animations on mobile
@include media-breakpoint-down(sm)
  [data-sal|='fade']
    opacity: 1

  [data-sal|='slide'], [data-sal|='zoom']
    opacity: 1
    transform: none

@include media-breakpoint-down(md)
  [data-sal|='flip']
    transform: none
