.mu-navbar
  box-shadow: 0px 2px 6px 0px $dark-15
  background-color: $white
  background-image: -webkit-linear-gradient(90deg, $white 0%, $white 77%, $light-blue 100%)
  background-image: linear-gradient(90deg, $white 0%, $white 77%, $light-blue 100%)

  .mu-logo
    width: 110px

  .nav-link
    color: $dark-65

    &:hover
      color: $dark-85

  .navbar-nav .nav-link.active
    color: $primary-blue
    font-weight: 500

  .navbar-toggler
    font-size: 1rem

    &:hover,
    &:active,
    &:focus
      box-shadow: none

  .dropdown
    .dropdown-toggle::after
      display: none

    .dropdown-toggle
      &:focus, &:active
        outline: none
        box-shadow: 0

  .dropdown-menu
    border-radius: 0
    min-width: 0
    border: 0
    padding: 0
    text-align: right

    .dropdown-item:hover
      color: $white
      background-color: $primary-blue

    .active
      background-color: $white
      color: $primary-blue
      font-weight: 500

      &:hover
        background-color: $white

  @include media-breakpoint-up(md)
    .mu-logo
      width: 130px

    .dropdown:hover .dropdown-menu
      display: block

    .dropdown:not(:hover) .dropdown-menu
      display: none !important

    .dropdown-menu
      background: transparent
      text-align: left
      position: relative
      padding-top: 1.7rem
      margin-top: 0

      .dropdown-item
        background-color: white

      .active
        color: $white
        font-weight: 400
        background-color: $primary-blue

        &:hover
          background-color: $primary-blue

  @include media-breakpoint-up(lg)
    .mu-logo
      width: 150px

    .dropdown-menu
      padding-top: 2rem
