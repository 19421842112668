$primary-blue: #2A80C7
$event-blue: #2581c8
$light-blue: #CDE8FF
$lighter-blue: #ecf3fa
$highlight-blue: #97D0FF

$box-shadow: #A5BDD1
$articles-shadow: #EAEBED
$light-gray: #d9d9d9
$lighter-gray: #F5F5F5
$event-gray: #b1b1b1

$dark-15: rgba(0, 0, 0, 0.15)
$dark-25: rgba(0, 0, 0, 0.25)
$dark-35: rgba(0, 0, 0, 0.35)
$dark-45: rgba(0, 0, 0, 0.45)
$dark-65: rgba(0, 0, 0, 0.65)
$dark-85: rgba(0, 0, 0, 0.85)

$brand-blue: #0080C8
$brand-lightblue: #87BBE6
$body-bg: rgb(237, 240, 245)
