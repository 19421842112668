.search
  border-bottom: 2px solid $light-gray

  input
    height: 40px
    background-clip: initial

    &, &:focus, &:active
      border: 0
      border-radius: 0
      box-shadow: none
      border-right: none

  #search-results
    position: absolute
    margin-top: 40px
    cursor: pointer

    ul
      list-style: none
      padding-left: 0
      z-index: 2000
      background-color: $white
      box-shadow: 0px 2px 12px 0px $articles-shadow

      li
        .ui-menu-item-wrapper
          padding: 5px 10px

          &.ui-state-active
            background-color: $lighter-blue
