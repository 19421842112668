.subscription-body
    border-style: none

.subscription-content
  padding: 7rem .1rem
  background: $lighter-blue

.subscription-header
  display: flex
  justify-content: center

.subscription-input
  margin-bottom: 1rem
  margin-left: 1rem
  margin-right: 1rem

.subscription-input-box
  border: 1px solid $primary-blue
  background: $white

sub
  vertical-align: super
  font-size: 0.625rem

.subscription-input-hint
  font-size: 0.7rem

.col-half
  flex: 0 0 auto
  width: 4%

.subscription-footer
  margin-top: 5rem
  margin-left: 1rem
  margin-right: 1rem

.subscription-support-text
  color: $dark-45
  font-size: 0.7rem
  margin-bottom: 0.7rem

#progressbar
  display: flex
  margin-bottom: 30px
  overflow: hidden
  color: lightgrey
  overflow: scroll

#progressbar .active
  color: $primary-blue

.progress-step
  list-style-type: none
  font-size: 1rem
  float: left
  position: relative

#progressbar #one:before
  content: "1"

#progressbar #two:before
  content: "2"

#progressbar #three:before
  content: "3"

#progressbar #four:before
    content: "4"

// Content represents spaces used for progressbars
// \00a0 -> unicode character for no-break space
.hr-line:before
  content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0"
  text-decoration: line-through
  line-height: 2.5
  margin: 15px

@include media-breakpoint-up(md)
  .hr-line:before
    content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0"
    text-decoration: line-through
    line-height: 2.5
    margin: 15px

@include media-breakpoint-up(lg)
  .hr-line:before
    content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0"
    text-decoration: line-through
    line-height: 2.5
    margin: 15px


@include media-breakpoint-up(xl)
  .hr-line:before
    content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0"
    text-decoration: line-through
    line-height: 2.5
    margin: 15px

@include media-breakpoint-up(xxl)
  .hr-line:before
    content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0"
    text-decoration: line-through
    line-height: 2.5
    margin: 15px

.progress-step:before
  width: 40px
  height: 40px
  line-height: 35px
  display: block
  color: gray
  border: 1px solid gray
  background: transparent
  border-radius: 50%
  margin: 0 auto 10px auto
  padding: 2px
  text-align: center

.progress-step:after
    content: ''
    width: 100%
    height: 2px
    background: lightgray
    position: absolute
    left: 0
    top: 25px
    z-index: -1

.progress-step.active:before,
.progress-step.active:after
  background: $primary-blue
  color: $white

.progress
  height: 20px

.progress-bar
  background-color: $primary-blue

.fit-image
  width: 100%
  object-fit: cover

.subscription-form-group
  label
    width: inherit

.subscription-form-check-label
  width: 100% !important
  text-align: left !important
  margin-top: 0 !important

.subscription-form-radio-label
  width: 3rem !important
  text-align: left !important
  float: none !important
  margin: 0 1em !important

@include media-breakpoint-up(sm)
  .subscription-content
    padding: 7rem 1rem

@include media-breakpoint-up(md)
  .subscription-body
    margin: 3rem

  .subscription-input
    margin-bottom: 1rem
    margin-left: 3rem
    margin-right: 3rem

  .subscription-footer
    margin-top: 5rem
    margin-left: 3rem
    margin-right: 3rem

@media (min-width: 768px) and (max-width: 1195px)
  .subscription-input-second
    margin-top: 1.5em

@include media-breakpoint-up(lg)
  .subscription-content
    padding: 7rem 3rem

  .subscription-form-radio
    display: flex

  .subscription-input
    margin-bottom: 1rem
    margin-left: 5rem
    margin-right: 5rem

  .subscription-footer
    margin-top: 5rem
    margin-left: 5rem
    margin-right: 5rem

@include media-breakpoint-up(xl)
  .subscription-form-double
    display: flex

  .subscription-form-control
    width: 50%

  .subscription-body
    label
      margin-right: 1em
      text-align: right
      font-weight: 500
      margin-top: 0.5rem

  .subscription-content
    padding: 7rem
