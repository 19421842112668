:root
  font-size: 16px

body
  font-size: 1rem

html, body
  min-height: 100vh

.rounded-7
  border-radius: .7rem

.article-white-space
  white-space: pre-line

input, textarea, select, button, .btn, .form-control, .form-check-input
  &:focus, &:active
    outline: none
    box-shadow: 0 0 2px 0 $box-shadow

@include media-breakpoint-up(md)
  .w-md-25
    width: 25%

  .w-md-30
    width: 30%

select
  -webkit-appearance: none !important
  -moz-appearance: none !important
  appearance: none !important
