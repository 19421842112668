.pagination
  margin: 3em 0 0 auto
  padding: 0 1em
  justify-content: center
  align-items: center

  a
    color: $dark-45
    text-decoration: none

  em
    font-style: normal

  .previous_page, .next_page
    line-height: 25px
    &.disabled, span
      display: none

  .previous_page
    &:before
      content: '<'
    &:after
      display: none

  .next_page:after
    content: '>'
    display: inline-block

  .current
    border-color: $primary-blue
    color: $primary-blue
    &:hover
      background-color: $white

  a, em, .gap
    height: 30px
    width: 30px
    line-height: 30px
    text-align: center
    border: 1px solid $light-gray
    border-radius: 4px
    margin: 0 4px
    &:hover
      cursor: pointer
      text-decoration: none !important
      background-color: $lighter-blue
