.contact-map
  min-height: 200px

.contact
  .form-control, .form-check-input
    border: 1px solid $lighter-gray
    background-color: $lighter-gray

  .form-control
    margin-top: .5rem

  .form-check-input:checked
    background-color: $primary-blue
    border-color: $primary-blue
