.agenda
  background: url("../images/backgrounds/agenda.webp") no-repeat (center / cover)

  .agenda-list
    list-style: none

    li
      background-color: $white
      margin-bottom: .5rem
      padding-left: .5rem
      display: flex

    li:before
      content: "\25C6"
      color: $primary-blue
      display: inline-block
      margin-right: .5rem

  @include media-breakpoint-up(md)
    background: url("../images/backgrounds/agenda_md.webp") no-repeat (center / cover)

  @include media-breakpoint-up(lg)
    .agenda-diamonds
      font-size: $h3-font-size

    .agenda-list
      font-size: $h4-font-size * 0.9

      li
        margin-bottom: 1rem
        padding-right: .5rem
