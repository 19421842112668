.page-footer
  background-color: $primary-blue
  color: $white
  font-size: .9rem

  a
    color: $white
    text-decoration: none

    &:hover
      color: $light-blue

  .mu-logo-white
    width: 110px

  @include media-breakpoint-up(md)
    font-size: 1rem

    .mu-logo-white
      width: 130px

  @include media-breakpoint-up(lg)
    .mu-logo-white
      width: 150px
