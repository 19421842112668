@include media-breakpoint-up(md)
  .welcome-area
    min-height: 68vh

    .welcome-box
      font-size: $h2-font-size
      font-weight: 600

    .welcome-border
      border: 2px solid $white
      border-radius: .75rem

    .welcome-margin
      margin: 2.2rem 1.7rem

@include media-breakpoint-up(xxl)
  .welcome-area
    .welcome-margin
      margin: 2.5rem 2rem
