.article-card
  box-shadow: 0px 2px 12px 0px $articles-shadow
  border: 0px
  border-radius: .4rem

  .card-img-top
    border-top-left-radius: .4rem
    border-top-right-radius: .4rem

  a
    text-decoration: none
    color: $dark-65
    transition: all .5s ease

    &:hover
      opacity: .7

  .image-start, .image-end
    background: no-repeat center/cover

  .image-start
    border-top-left-radius: .4rem
    border-bottom-left-radius: .4rem

  .image-end
    border-top-right-radius: .4rem
    border-bottom-right-radius: .4rem

  @include media-breakpoint-up(xxl)
    .image-start, .image-end
      min-height: 240px
