/* TODO: Remove when heading color 'Machen Sie mit" is changed */
.text-primary-blue
  color: $primary-blue

.text-dark-45
  color: $dark-45

.text-dark-65
  color: $dark-65

.text-dark-85
  color: $dark-85

.fw-medium
  font-weight: 500

.highlight-title
  color: $dark-85
  font-size: $h5-font-size
  font-weight: 700
  background-image: linear-gradient(180deg, rgba(255,255,255,0) 60%, $highlight-blue 60%) 
  padding-bottom: 2px
  -webkit-box-decoration-break: clone
  box-decoration-break: clone

.event-title
  font-size: $h4-font-size
  font-weight: 500

.line-title
  font-size: $h5-font-size
  font-weight: 500
  border-left: 4px solid $primary-blue
  padding-left: 1rem
  margin-bottom: .5rem

.bold-italic-title
  font-size: $h3-font-size
  font-weight: 900
  font-style: italic

.title
  font-size: $h5-font-size
  font-weight: 500

.subtitle
  font-size: $h5-font-size * 0.9
  font-weight: 500

.primary-link
  color: $primary-blue
  text-decoration: none

  &:hover
    color: darken($primary-blue, 10%)

.lead-text-lg
  font-size: $h5-font-size * 0.9

.article-show
  h3
    font-size: 1.2em
    color: $dark-85
    line-height: 1.2

  a
    @extend .primary-link

@include media-breakpoint-up(sm)
  .highlight-title
    font-size: $h4-font-size

@include media-breakpoint-up(md)
  .highlight-title, .event-title
    font-size: $h3-font-size

  .line-title
    font-size: $h4-font-size
    margin-bottom: .8rem

  .bold-italic-title
    font-size: $h2-font-size

  .lead-text
    font-size: 1.25rem

@include media-breakpoint-up(lg)
  .bold-italic-title
    font-size: $h1-font-size * 1.2

  .sublead-text-lg
    font-size: $h4-font-size

  .lead-text-lg
    font-size: $h3-font-size

@include media-breakpoint-up(xl)
  .bold-italic-title
    font-size: $h1-font-size * 1.4
