.bg-primary
  background-color: $primary-blue

.bg-lighter-blue
  background-color: $lighter-blue

.bg-brand-blue
  background-color: $brand-blue

.header-image
  min-height: 40vh

.header-space
  height: 115px

.bg-welcome
  background: url("../images/backgrounds/augsburg_panorama.webp") no-repeat (center / cover)

.bg-articles
  background: url("../images/backgrounds/augsburg_statues.webp") no-repeat (center / cover)

.bg-events
  background: url("../images/backgrounds/augsburg_fountain.webp") no-repeat (center / cover)

.bg-party
  background: url("../images/backgrounds/augsburg_rathausplatz.webp") no-repeat (center / cover)

.bg-companies
  background: url("../images/backgrounds/chair.webp") no-repeat (center / cover)

.bg-members
  background: url("../images/backgrounds/members.webp") no-repeat (center / cover)

.bg-contact
  background: url("../images/backgrounds/mountains.webp") no-repeat (center / cover)

.bg-map
  background: url("../images/backgrounds/map.webp") no-repeat (center / cover)

.bg-placeholder
  background-image: url("../images/placeholder-logo.svg") !important

// small mobile landscape
@media (min-width: 550px)
  .header-image
    min-height: 80vh

@include media-breakpoint-up(md)
  .bg-welcome
    background: url("../images/backgrounds/augsburg_panorama_md.webp") no-repeat (center / cover)

  .bg-articles
    background: url("../images/backgrounds/augsburg_statues_md.webp") no-repeat (center / cover)

  .bg-events
    background: url("../images/backgrounds/augsburg_fountain_md.webp") no-repeat (center / cover)

@include media-breakpoint-up(lg)
  .bg-welcome
    background: url("../images/backgrounds/augsburg_panorama_lg.webp") no-repeat (center / cover)

@include media-breakpoint-up(xxl)
  .bg-welcome
    background: url("../images/backgrounds/augsburg_panorama_xl.webp") no-repeat (center / cover)

  .bg-articles
    background: url("../images/backgrounds/augsburg_statues_xl.webp") no-repeat (center / cover)

  .bg-events
    background: url("../images/backgrounds/augsburg_fountain_xl.webp") no-repeat (center / cover)

  .bg-party
    background: url("../images/backgrounds/augsburg_rathausplatz_xl.webp") no-repeat (center / cover)

  .bg-companies
    background: url("../images/backgrounds/chair_xl.webp") no-repeat (center / cover)

  .bg-members
    background: url("../images/backgrounds/members_xl.webp") no-repeat (center / cover)

  .bg-contact
    background: url("../images/backgrounds/mountains_xl.webp") no-repeat (center / cover)
