.gradient-heading-end, .gradient-heading-start
  font-weight: 700
  width: 140px
  height: 40px
  background-color: $highlight-blue
  display: flex
  align-items: center

.gradient-heading-end
  background-image: -webkit-linear-gradient(63deg, $highlight-blue 0%, $white 100%)
  background-image: linear-gradient(63deg, $highlight-blue 0%, $white 100%)

.gradient-heading-start
  background-image: -webkit-linear-gradient(-63deg, $highlight-blue 0%, $white 100%)
  background-image: linear-gradient(-63deg, $highlight-blue 0%, $white 100%)
  padding-left: 2.3rem

.gradient-heading-end, .gradient-heading-start-text
  -ms-transform: skewX(20deg)
  -webkit-transform: skewX(20deg)
  transform: skewX(20deg)

.gradient-heading-end-text, .gradient-heading-start
  -ms-transform: skewX(-20deg)
  -webkit-transform: skewX(-20deg)
  transform: skewX(-20deg)

@include media-breakpoint-up(md)
  .gradient-heading-end, .gradient-heading-start
    font-size: $h3-font-size
    width: 220px
    height: 50px

  .gradient-heading-start
    padding-left: 3.5rem
