.btn-primary-blue-sm
  color: $white
  background: linear-gradient(to left, $primary-blue 50%, shade-color($primary-blue, 15%) 50%)
  background-size: 200% 100%
  background-position: right bottom
  border-radius: .25rem
  border: none
  transition: all .5s ease

  &:hover
    color: $white
    background-position: left bottom

.btn-primary-blue-md
  color: $white
  background: linear-gradient(to left, $primary-blue 50%, shade-color($primary-blue, 15%) 50%)
  background-size: 200% 100%
  background-position: right bottom
  padding: .5rem 2rem
  border-radius: .4rem
  border: none
  font-size: 1.1rem
  transition: all .5s ease

  &:hover
    color: $white
    background-position: left bottom

.btn-primary-blue
  color: $white
  background: linear-gradient(to left, $primary-blue 50%, shade-color($primary-blue, 15%) 50%)
  background-size: 200% 100%
  background-position: right bottom
  padding: .5rem 1.8rem
  border-radius: .35rem
  border: none
  box-shadow: 0px 4px 12px 0px $box-shadow
  font-size: 1.1rem
  font-weight: 500
  transition: all .5s ease

  &:hover
    color: $white
    background-position: left bottom

.btn-primary-blue-outline
  color: $primary-blue 
  background: transparent
  background-size: 200% 100%
  background-position: right bottom
  padding: .5rem 1.8rem
  border-radius: .35rem
  border: 1px solid $primary-blue 
  box-shadow: 0px 4px 12px 0px $box-shadow
  font-size: 1.1rem
  font-weight: 500
  transition: all .5s ease

  &:hover
    color: $white
    background-position: left bottom
    background: $primary-blue
