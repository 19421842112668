.companies
  margin-top: 5.8rem

  .companies-content
    position: relative
    margin-top: -4.2rem

  @include media-breakpoint-up(md)
    margin-top: 8rem

    .companies-content
      margin-top: -5rem

  @include media-breakpoint-up(xl)
    margin-bottom: 4.5rem !important
