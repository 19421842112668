.event
  min-height: 120px

  .event-icon-mark-container
    display: flex
    justify-content: space-around
    width: 95px

  .event-icon-mark
    width: 5px
    height: 4px

  .event-icon
    width: 95px
    text-align: center
    border-radius: 10px

    .day
      color: $white
      height: 55px
      font-size: 35px
      font-weight: 700

    .month
      color: $white
      height: 35px
      font-size: 16px
      border-top: 1px solid $white
      display: flex
      align-items: center
      justify-content: center

.event-gray
  .event-icon-mark, .event-icon
    background-color: $event-gray

.event-blue
  .event-icon-mark, .event-icon
    background-color: $event-blue
